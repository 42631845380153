import request from '../../utils/request';

/**
 * 后台管理人员
 * @param query
 * @returns {AxiosPromise}
 */



//修改密码
export const Uppawss = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/User/up/paw',
        method: 'post',
        data: query
    });
};




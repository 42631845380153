<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 账号设置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-avatar size="large" :src="UserInfo.Avatar" class="avatar"></el-avatar>
        <el-descriptions>
          <el-descriptions-item label="用户名">{{UserInfo.Name}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{UserInfo.Mobile}}</el-descriptions-item>
          <el-descriptions-item label="电子邮箱">{{UserInfo.Email}}</el-descriptions-item>
          <el-descriptions-item label="权限">{{UserInfo.Jurisdiction}}</el-descriptions-item>
          <el-descriptions-item label="操作">
            <el-button type="text" @click="openUpPaw">更改密码</el-button>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <UpPaw ref="UpPaw"></UpPaw>
  </div>
</template>

<script>
import UpPaw from './com/UpPaw'

export default {
  data() {
    return {
      UserInfo: this.$store.state.UserInfo
    };
  },
  methods:{
    openUpPaw(){
      this.$refs.UpPaw.open()
    }
  },
  components:{
    UpPaw
  }
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.green {
  color: green;
}

.container {
  height: 600px;
  .avatar{
    margin-bottom: 15px;
  }
}

</style>

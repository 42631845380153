<template>
  <el-dialog v-model="view" width="500px" title="修改密码">
    <el-form label-width="90px" class="principal">
      <el-form-item label="密码" :required="true">
        <el-input v-model="form.Paw" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" :required="true">
        <el-input v-model="form.Paws" show-password></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
         <el-button @click="save">提交</el-button>
        <el-button @click="close">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>


import {Uppawss} from "../../../../api/Admin/Settings";

export default {
  data() {
    return {
      query: {
        id: ''
      },
      view: false,
      form: {
        Paw: "",
        Paws: "",
      },
    }
  },
  methods: {
    close() {
      this.view = false
    },
    open(id) {
      this.view = true
      this.query.id = id
    },
    save() {
      let id = this.query.id
      let Paw = this.form.Paw
      let Paws = this.form.Paws
      this.openLoading('处理中')
      Uppawss({id, Paw, Paws}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          this.$message.success(res.message);
          this.close()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.fnRed {
  color: #910900;
  font-size: 11px;
}

.batch {
  float: left;
}

.tableClass { //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}


</style>
`
